if ('objectFit' in document.documentElement.style === false) {
  window.onImageMount = () => {
    const imgToFit = document.querySelectorAll('img[data-object-fit=true]')

    for (let i = 0; i < imgToFit.length; i++) {
      const container = imgToFit[i].parentElement.parentElement
      const imgToFitUrl = imgToFit[i].src

      if (imgToFitUrl) {
        container.classList.add('objectFit')
        container.style.backgroundImage = `url(${imgToFitUrl})`
      }
    }
  }
}
